import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Swiper, SwiperSlide} from 'swiper/react';
import cx from 'classnames';

import {
  Breadcrumb,
  Card,
  HorizontalCardDigital,
  HorizontalCard,
  Layout,
  Seo,
  Share,
} from '@app/components';
import {useLocale} from '@app/hooks';
import {
  query,
  collection,
  where,
  getDocs,
  limit,
  orderBy,
} from 'firebase/firestore';
import {db} from '../../firebase';

import sbbImage from '../assets/images/sbb-sm.jpeg';

type PageTemplatePropTypes = {
  data: {
    allMarkdownRemark: {
      nodes: {
        excerpt: string;
        frontmatter: {
          title: string;
          author: string;
          date: string;
          cover: any;
          imageUrl: string;
        };
      }[];
    };
  };
  pageContext: {
    title: string;
    language: string;
  };
};

const PageTemplate: React.FC<PageTemplatePropTypes> = ({data, pageContext}) => {
  let posts = data.allMarkdownRemark.nodes;

  const [fbPosts, setFbPosts] = useState([]);
  const [showPosts, setShowPosts] = useState(posts);
  const [isLoad, setIsLoad] = useState(false);

  const SCOPE_OPTIONS = {
    scope: 'pages.Editor',
  };
  const {t} = useLocale();

  useEffect(() => {
    const mappingData: any = [];
    console.log(pageContext.title);
    const unSub = async () => {
      const q = query(
        collection(db, 'posts'),
        where('categories', 'array-contains', pageContext.title),
        where('language', '==', pageContext.language),
        orderBy('createAt', 'desc'),
        limit(21),
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        mappingData.push({
          excerpt: doc.data().article,
          frontmatter: {
            title: doc.data().title,
            author: doc.data().author,
            date: new Date(doc.data().createAt.seconds * 1000).toISOString(),
            imageUrl: doc.data().imageUrl,
          },
        });
        console.log(doc.id, ' => ', doc.data());
      });

      const showingPosts = [...mappingData, ...posts];

      showingPosts.sort(function (a, b) {
        var keyA = new Date(a.frontmatter.date),
          keyB = new Date(b.frontmatter.date);
        console.log(keyA, keyB);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });

      setShowPosts(showingPosts);
      setIsLoad(true);
    };

    unSub();
  }, []);

  return (
    <Layout>
      <Seo title={pageContext.title} />
      {pageContext.title === 'Dijital Medya' && (
        <div className="container mx-auto px-8 mt-10">
          <div className="author">
            <div className="author__photo">
              <img src={sbbImage} alt="SBB" className="rounded-full" />
            </div>
            <div className="author__info lg:text-left">
              <h1>Dr. Serdar Bora Bayraktaroğlu</h1>
              <span className="text-lg text-gray-500 font-medium">
                Estetik Cerrah
              </span>
            </div>
          </div>
        </div>
      )}

      <div
        className={cx(
          'container mx-auto px-4 py-20 lg:px-0 xl:max-w-container-xl',
          {'opacity-0': !isLoad},
        )}
      >
        {pageContext.title === 'Dijital Medya' && (
          <div
            className="grid lg:grid-cols-1 lg:gap-x-10 gap-y-5"
            style={{width: '70%'}}
          >
            {showPosts.map((post) => (
              <HorizontalCardDigital
                title={post.frontmatter.title}
                cover={post.frontmatter.cover?.childImageSharp?.fluid.src}
                imageUrl={post.frontmatter.imageUrl}
                key={post.frontmatter.title}
                description={post.excerpt}
              />
            ))}
          </div>
        )}
        {pageContext.title !== 'Dijital Medya' && (
          <>
            <div className="grid lg:grid-cols-2 lg:gap-x-10 gap-y-20">
              {showPosts.slice(0, 2).map((post) => (
                <Card
                  title={post.frontmatter.title}
                  author={post.frontmatter.author}
                  date={post.frontmatter.date}
                  cover={post.frontmatter.cover?.childImageSharp?.fluid}
                  imageUrl={post.frontmatter.imageUrl}
                  key={post.frontmatter.title}
                />
              ))}
            </div>
            <div className="grid lg:grid-cols-3 lg:gap-x-10 gap-y-20 mt-20">
              {showPosts.slice(2, 21).map((post) => (
                <Card
                  title={post.frontmatter.title}
                  description={post.excerpt}
                  author={post.frontmatter.author}
                  date={post.frontmatter.date}
                  cover={post.frontmatter.cover?.childImageSharp?.fluid}
                  imageUrl={post.frontmatter.imageUrl}
                  key={post.frontmatter.title}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {showPosts.slice(21).length > 0 && (
        <div
          className="mb-8"
          style={{
            background: '#f1f1f1',
            borderBottom: '3px dotted #ddd',
            borderTop: '3px dotted #ddd',
          }}
        >
          <div className="container mx-auto px-4 pt-8 lg:px-0 xl:max-w-container-xl">
            <span className="text-2xl block font-bold text-black">
              {t('labels.contentTitle', SCOPE_OPTIONS)}
            </span>
            <div className="pb-20 pt-10">
              <Swiper
                spaceBetween={20}
                slidesPerView={1}
                breakpoints={{
                  '768': {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  '1024': {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  '1280': {
                    slidesPerView: 6,
                    spaceBetween: 20,
                  },
                }}
              >
                {showPosts.slice(21).map((post) => (
                  <SwiperSlide key={post.frontmatter.title}>
                    <Card
                      title={post.frontmatter.title}
                      cover={post.frontmatter.cover?.childImageSharp?.fluid}
                      imageUrl={post.frontmatter.imageUrl}
                      author={post.frontmatter.author}
                      isSmall
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 py-2.5 mb-8 lg:px-0 xl:max-w-container-xl">
        <Share title={pageContext.title} />
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($title: String!, $language: String!) {
    allMarkdownRemark(
      sort: {fields: frontmatter___date, order: DESC}
      filter: {
        frontmatter: {categories: {eq: $title}, language: {eq: $language}}
      }
    ) {
      nodes {
        excerpt(pruneLength: 140)
        frontmatter {
          title
          author
          date
          cover {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
